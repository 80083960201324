import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { collection, getDocs, updateDoc, doc, getDoc, arrayRemove } from 'firebase/firestore';
import { db } from "../service/firebase";
import { FETCH, FETCHING, IDLE, FETCH_ERROR } from "../utils/constants";
import { capitalize } from "../utils/extension"
import { getFunctions, httpsCallable } from "firebase/functions";
import { getStorage, ref, deleteObject } from "firebase/storage";

export const deleteCategoryDocument = createAsyncThunk("categories/deleteDocument", async (data) => {
    try{
        const id = data.id
        const docNumber = data.doc.split("%2F").slice(-1)[0].split("?")[0]
        const categoryType = data.categoryType.replace(' ', '_')
        const path = `/categories/${categoryType}/${id}/docs/${docNumber}`
        const storage = getStorage();

        const storageRef = ref(storage, path);

        await deleteObject(storageRef);
        
        const docRef = doc(db, "categorias", data.id);
        await updateDoc(docRef, {
            "documentos": arrayRemove(data.doc)
        });
        window.location.reload()
    }
    catch (error) {
        console.log("Could not delete category picture", error);
    }
});

export const updateCategoryStatus = createAsyncThunk("categories/updateStatus", async (data) => {
    try{
        const docRef = doc(db, "categorias", data.id);
        await updateDoc(docRef, {
            "enable": data.status,
        });
        const functions = getFunctions();
        const addMessage = httpsCallable(functions, 'createNotification');
        addMessage({ 
            userUid: data.userUid,
            remoteUserUid: data.userUid,
            type: data.status ? 'ENABLED' : 'DISABLED',
        })
        .then((result) => {
            console.log('Cloud Function',result);
        });
    }
    catch (error) {
        console.log("Could not update category status", error);
    }
});

export const updateCategory = createAsyncThunk("categories/updateCategory", async (data) => {
    try{
        const docRef = doc(db, "categorias", data.id);
        await updateDoc(docRef, {
            "title": data.categ.title,
            "bio": data.categ.bio,
            "dni": data.categ.dni,
            "tags": data.categ.tags,
            "description": data.categ.description,
            "businessEmail": data.categ.businessEmail,
            "hours": data.categ.hours,
        });
    }
    catch (error) {
        console.log("Could not update category status", error);
    }
});

export const getCategories = createAsyncThunk("categories/get", async () => {
    try{
        let list = []
        let querySnapshot = await getDocs(collection(db, "categorias"))
        querySnapshot.forEach((doc) => {
            let category = {
                id: doc.id,
                title: doc.data()['title'],
                enable: doc.data()['enable'],
                mobileNumber: doc.data()['mobileNumber'] === undefined ? "" : doc.data()['mobileNumber'],
                businessEmail: doc.data()['businessEmail'] === undefined ? "" : doc.data()['businessEmail'],
                userUid: doc.data()['userUid'],
                picture: doc.data()['picture'],
                categoryType: doc.data()['categoryType'],
             //   ubicacion: doc.data()['workingZone'] === undefined ? "" : doc.data()['workingZone'].address,
                ubicacion: doc.data()['workingAddress'] === undefined ? "" : doc.data()['workingAddress'].province,
                documents: doc.data()['documentos'] === undefined ? "" : doc.data()['documentos'].length,
            }
            list.push(category)
        });
        return list
    }
    catch (error) {
        console.log("Could not get categories", error);
        return []
    }
});

export const getCategorySelected = createAsyncThunk("categories/getSelected", async (data) => {
    try{
        const docRef = doc(db, "categorias", data.id);
        let docSnap = await getDoc(docRef);
        let res = docSnap.data()
        let category = {
            id: data.id,
            title: res.title,
            bio: res.bio ?? '',
            categoryType: res.categoryType.replace('_', ' '),
            businessEmail: res.businessEmail ?? '',
            description: res.description ?? '',
            enable: res.enable,
            mobileNumber: res.mobileNumber ?? '',
            picture: res.picture ?? '',
            userUid: res.userUid,
            hours: res.hours ?? '',
            workingAddress: res.workingAddress,
            docs: [...res.documentos],
            tags: res.tags === undefined ? [] : [...res.tags],
            dni: res.dni ?? ''
        }
        return {category}
    }
    catch (error) {
        console.log("Could not update category status", error);
    }
});

const categoriesSlice = createSlice({
    name: "categories",
    initialState: {
        categoryList: [],
        categorySelected: {},
        categoryStatus: IDLE,
        updateStatus: IDLE,
        deleteDocument: IDLE,
    },
    reducers: {
        clearUpdateStatus(state, action) {
            state.updateStatus = IDLE
        },
        clearDeleteDocument(state, action) {
            state.deleteDocument = IDLE
        },
        allCategories(state, action) {
            console.log(action.payload);
            return [] 
        },
        categorySelected(state, action) {
            console.log(action.payload);
            return {}
        },
    },
    extraReducers: {
        [getCategories.pending]: (state, action) => {
            console.log("Fetching getCategories.....");
        },
        [getCategories.fulfilled]: (state, action) => {
            console.log("Fetched getCategories.....");
            state.categoryList = action.payload;
        },
        [getCategories.rejected]: (state, action) => {
            console.log("Failed getCategories.....");
        },
        [updateCategory.pending]: (state, action) => {
            console.log("Fetching updateCategory.....");
            state.updateStatus = FETCHING;
        },
        [updateCategory.fulfilled]: (state, action) => {
            console.log("Fetched updateCategory.....");
            state.updateStatus = FETCH;
        },
        [updateCategory.rejected]: (state, action) => {
            console.log("Failed updateCategory.....");
            state.updateStatus = FETCH_ERROR;
        },
        [deleteCategoryDocument.pending]: (state, action) => {
            console.log("Fetching deleteCategoryDocument.....");
            state.deleteDocument = FETCHING;
        },
        [deleteCategoryDocument.fulfilled]: (state, action) => {
            console.log("Fetched deleteCategoryDocument.....");
            state.deleteDocument = FETCH;
        },
        [deleteCategoryDocument.rejected]: (state, action) => {
            console.log("Failed deleteCategoryDocument.....");
            state.deleteDocument = FETCH_ERROR;
        },
        [getCategorySelected.pending]: (state, action) => {
            console.log("Fetching getCategorySelected.....");
            state.categoryStatus = FETCHING;
        },
        [getCategorySelected.fulfilled]: (state, action) => {
            console.log("Fetched getCategorySelected.....");
            state.categorySelected = action.payload.category;
            state.categoryStatus = FETCH;
        },
        [getCategorySelected.rejected]: (state, action) => {
            console.log("Failed getCategorySelected.....");
            state.categoryStatus = FETCH_ERROR;
        },
    },
});

export const { allCategories, categorySelected, categoryStatus, updateStatus, clearUpdateStatus, deleteDocument, clearDeleteDocument } = categoriesSlice.actions;

export const categoriesReducers = categoriesSlice.reducer;