import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import { BrowserRouter as Router } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css"
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit'
import { categoriesReducers } from './app/features/categories'
import { stateReducers, cityReducers } from './app/features/address'
import { tagsReducers } from './app/features/tags'
import { AuthProvider } from "./app/contexts/AuthContext"

export const store = configureStore({
  reducer: {
    categoriesReducers,
    stateReducers,
    cityReducers,
    tagsReducers,
  },
});

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <Router>
        <AuthProvider>
          <App />
        </AuthProvider>
      </Router>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);
