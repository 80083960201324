import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getDoc, doc } from 'firebase/firestore';
import { db } from "../service/firebase";
import { FETCH, FETCHING, IDLE, FETCH_ERROR } from "../utils/constants";

export const getCategoryTag = createAsyncThunk("tagCategory/get", async (data) => {
    try{
        let tags = []
        const docRef = doc(db, "app_data", 'tags');
        let docSnap = await getDoc(docRef);
        let res = docSnap.data()[data.categoryType.replaceAll(' ', '_')]
        let response = res.map(e => e.tags)
        for (var i = 0; i < response.length; i++) {
            tags = tags.concat(response[i])
        }
        return tags
    }
    catch (error) {
        console.log("Could not get tags category", error);
        return []
    }
});

const tagsSlice = createSlice({
    name: "tags",
    initialState: {
        tagCategory: [],
        tagCategoryStatus: IDLE,
    },
    extraReducers: {
        [getCategoryTag.pending]: (state, action) => {
            console.log("Fetching getCategoryTag.....");
            state.tagCategoryStatus = FETCHING
        },
        [getCategoryTag.fulfilled]: (state, action) => {
            console.log("Fetched getCategoryTag.....");
            state.tagCategory = action.payload;
            state.tagCategoryStatus = FETCH
        },
        [getCategoryTag.rejected]: (state, action) => {
            console.log("Failed getCategoryTag.....");
            state.tagCategoryStatus = FETCH_ERROR
        },
    },
});

export const { tagCategory } = tagsSlice.actions;

export const tagsReducers = tagsSlice.reducer;