import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FETCH, FETCHING, IDLE, FETCH_ERROR } from "../utils/constants";
import { capitalize } from "../utils/extension"

export const getStates = createAsyncThunk("states/getStates", async () => {
    try{
        let list = []
        let response = await fetch("https://apis.datos.gob.ar/georef/api/provincias")
        const result = await response.json()
        const provincias = result.provincias
        provincias.forEach((st) => {
            let state = {
                id: st["id"],
                name: st["nombre"],
            }
            list.push(state)
        });
        list.sort(function(a, b){
            if(a.name < b.name) { return -1; }
            if(a.name > b.name) { return 1; }
            return 0;
        })
        return list
    }
    catch (error) {
        console.log("Could not get states", error);
        return []
    }
});

export const getCities = createAsyncThunk("cities/getCities", async (stateId) => {
    try{
        let list = []
        let municipios = []
        if(stateId === "06"){
            let response1 = await fetch(`https://apis.datos.gob.ar/georef/api/municipios?provincia=${stateId}&max=400`)
            const result1 = await response1.json()
            municipios = result1.municipios
        }
        let response = await fetch(`https://apis.datos.gob.ar/georef/api/localidades?provincia=${stateId}&max=400`)
        const result = await response.json()
        const localidades = result.localidades
        console.log(stateId);
        localidades.concat(municipios).forEach((st) => {
            let city = {
                id: st["id"],
                name: capitalize(st["nombre"]),
            }
            list.push(city)
        });
        list.sort(function(a, b){
            if(a.name < b.name) { return -1; }
            if(a.name > b.name) { return 1; }
            return 0;
        })
        return list
    }
    catch (error) {
        console.log("Could not get states", error);
        return []
    }
});

const stateSlice = createSlice({
    name: "states",
    initialState: {
        states: [],
        stateStatus: IDLE,
    },
    reducers: {
        clearStateStatus(state, action) {
            state.stateStatus = IDLE
        },
    },
    extraReducers: {
        [getStates.pending]: (state, action) => {
            state.stateStatus = FETCHING
            console.log("Fetching getStates.....");
        },
        [getStates.fulfilled]: (state, action) => {
            console.log("Fetched getStates.....");
            state.stateStatus = FETCH
            state.states = action.payload;
        },
        [getStates.rejected]: (state, action) => {
            state.stateStatus = FETCH_ERROR
            console.log("Failed getStates.....");
        },
    },
});

const citySlice = createSlice({
    name: "cities",
    initialState: {
        cities: [],
        cityStatus: IDLE,
    },
    reducers: {
        clearCityStatus(state, action) {
            state.cityStatus = IDLE
        },
    },
    extraReducers: {
        [getCities.pending]: (state, action) => {
            state.cityStatus = FETCHING
            console.log("Fetching getCities.....");
        },
        [getCities.fulfilled]: (state, action) => {
            console.log("Fetched getCities.....");
            state.cityStatus = FETCH
            state.cities = action.payload;
        },
        [getCities.rejected]: (state, action) => {
            state.cityStatus = FETCH_ERROR
            console.log("Failed getCities.....");
        },
    },
});
export const { clearStateStatus, clearCityStatus } = stateSlice.actions;

export const stateReducers = stateSlice.reducer;
export const cityReducers = citySlice.reducer;